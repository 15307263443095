<template>
  <div class="player-box" v-if="liveDetail">
      <!-- 直播删除 -->
      <div v-if="liveDetail.status == 0 || liveDetail.data.data.state == 64" class="delete-status">
        <img :src="defaultImg" />
        <p class="delete-tips"><span class="delete-text">直播已下架</span></p>
      </div>
      <!-- 直播关闭(录播链接) -->
      <video
        v-else-if="liveDetail.data.data.state == 32"
        controls="controls"
        loop="loop"
        preload="auto"
        :src="liveDetail.data.data.playStreamUrl"
        :poster="
          !!liveDetail.data.data.cover
            ? liveDetail.data.data.cover
            : defaultImg
        "
      />
      <!-- 直播未开始 -->
      <div
        v-else-if="liveDetail.data.data.state == 8 || !liveDetail.data.data.playStreamUrl"
        class="delete-status"
      >
        <img
          :src="
            !!liveDetail.data.data.cover
              ? liveDetail.data.data.cover
              : defaultImg
          "
        />
        <p class="delete-tips"><span class="delete-text">直播未开始</span></p>
      </div>
    <!-- 图文直播 -->
    <div v-else-if="liveDetail.data.data.type == 1" class="img-txt-box">
      <iframe
        :src="liveDetail.data.data.appShortUrl"
        width="480px"
        height="100%"
        frameborder="0"
        allowfullscreen="true"
      ></iframe>
    </div>
    <div v-else class="player">
      <!-- 直播未开始或正在直播 -->
      <video-player
        class="video-player vjs-custom-skin"
        ref="videoPlayer"
        :playsinline="true"
        :options="playerOptions"
        @play="onPlayerPlay($event)"
        @pause="onPlayerPause($event)"
      >
      </video-player>
    </div>
  </div>
</template>
<script>
import { getLiveDetail } from "@/api/rold";
export default {
  name: "Play",
  props: {
    liveId: {
      type: String,
      default: "",
    }
  },
  data() {
    window.timerSet = null
    return {
      playerOptions: {},
      liveDetail: null,
      defaultImg: require("@/assets/img/detailImg/defaultBg.png"),
      endTime: 0,
    };
  },
  watch:{
    '$route':{
      handler(){
        window.clearTimeout(window.timerSet)
      }
    }
  },
  created() {
    this.getLiveDetail()
  },
  methods: {
    onPlayerPlay(e) {
      console.log(e);
    },
    onPlayerPause(e) {
      console.log(e);
    },
    getLiveDetail() {
      //'录播：1629732892101358  图文：162555211059556  未开始：1629972288575586'
      getLiveDetail({ liveId: this.liveId })
        .then((res) => {
          this.liveDetail = JSON.parse(res.data.data.result);
          if (this.liveDetail.data.data && this.liveDetail.data.data.type != 1 && this.liveDetail.data.data.state == 16) {
            if (
              this.liveDetail.data.data.playStreamUrl &&
              this.liveDetail.data.data.playStreamUrl != ""
            ) {
              var that = this;
              this.playerOptions = {
                playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
                autoplay: true, //如果true,浏览器准备好时开始回放。
                muted: false, // 默认情况下将会消除任何音频。
                loop: false, // 导致视频一结束就重新开始。
                preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
                language: "zh-CN",
                aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
                fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
                sources: [
                  {
                    withCredentials: false,
                    type: "application/x-mpegURL", //播放类型，如果为rtmp配置为rtmp/mp4
                    // src: "http://xcyplay.xinhuaxmt.com/zbcb/1626946003890559.m3u8?auth_key=1628384670-0-0-6544e114a1b86ab9cd1dec41e6e777df", //直播流地址
                    src: this.liveDetail.data.data.playStreamUrl,
                  },
                ],
                poster: this.liveDetail.data.data.cover, //你的封面地址
                width: document.documentElement.clientWidth,
                notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
                controlBar: {
                  // timeDivider: true,
                  // durationDisplay: true,
                  // remainingTimeDisplay: false,
                  // fullscreenToggle: true, //全屏按钮
                  timeDivider: true,
                  durationDisplay: true,
                  remainingTimeDisplay: true,
                  currentTimeDisplay: true, // 当前时间
                  volumeControl: false, // 声音控制键
                  playToggle: true, // 暂停和播放键
                  progressControl: true, // 进度条
                  fullscreenToggle: true, // 全屏按钮
                },
              };
              // 直播结束后刷新页面
              if(this.liveDetail.data.data.state == 16){
                this.endTime = this.liveDetail.data.data.endTime;
                setInterval(function() {
                  var nowTime = new Date();
                  var endTime = that.endTime;
                  console.log(endTime)
                  console.log(nowTime.valueOf())
                  if(nowTime.valueOf() >= endTime){
                    that.$router.go(0)
                  }
                }, 1000);
              }
            } else {
              window.timerSet = window.setTimeout(() => {
                this.getLiveDetail();
              }, 5000);
            }
          }else{
            window.clearTimeout(window.timerSet)
            return
          }
        })
        .catch(() => {
          console.log("456");
        });
    },
  },
};
</script>
<style lang="less" scoped>
.player-box {
  width: inherit;
  height: inherit;
  text-align: center;
  .img-txt-box {
    width: 100%;
    height: 100%;
  }
}
.delete-status {
  position: relative;
  .delete-tips {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    .delete-text {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 20px;
      color: #ffffff;
    }
  }
}
.vjs-tech{
  pointer-events: none;
}
</style>