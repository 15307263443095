<template>
  <div class="detail-box">
    <div v-if="!isHide" class="detail-nav">
      <div class="nav-box">
        <router-link class="nav-title" to="/pages/index/index?columnId=452">路演</router-link>
        <span>&gt;</span>
        <a class="nav-title" @click="reloadPage">{{detail.title}}</a>
      </div>
    </div>
    <div class="detail-contents">
      <div class="article-title">
        <h3 class="title-text">{{detail.title}}</h3>
        <div class="article-source">
          <p class="source-msg">
            <span v-if="detail.publishedTime">&nbsp;&nbsp;{{detail.publishedTime}}</span>
            <!-- {{detail.officeName}}
            <span v-if="detail.officeCode">（{{detail.officeCode}}）</span> -->
            
          </p>
          <div class="collect-box">

            <!-- <p class="collect-box" @click="collectRold"> -->
            <p v-if="!isHide" class="collect-left" @click="collectRold">
              <a class="not-collect" v-if="!detail.collectStatus" >
                <img src="~@/assets/img/indexImg/start.svg"/>
                <span>收藏</span>
              </a>
              <a class="is-collect" v-else >
              <img src="~@/assets/img/indexImg/start-active.svg"/>
                <span>取消收藏</span>
              </a>
            </p>
            <p class="share-article">
              <span class="share-tip">分享到：</span>
              <a id="share_weibo" class="weibo" @click="shareSina">
                <img class="share_icon" src="@/assets/img/detailImg/weibo.svg" alt="weibo" />
                <img class="share_icon active_icon" src="@/assets/img/detailImg/weibo_active.svg" alt="weibo" />
              </a>
              <a id="share_wechat" class="wechat">
                <img class="share_icon" src="@/assets/img/detailImg/wechat.svg" alt="wechat" />
                <img class="share_icon active_icon" src="@/assets/img/detailImg/wechat_active.svg" alt="wechat" />

                <span id="wechat_qrcode" class="wechart-qrcode" ref="wechartQr"></span>
              </a>
              <a id="share_qq" class="qq" @click="shareQQ">
                <img class="share_icon" src="@/assets/img/detailImg/qq.svg" alt="qq" />
                <img class="share_icon active_icon" src="@/assets/img/detailImg/qq_active.svg" alt="qq" />
              </a>
            </p>
          </div>
          <div class="name-code">{{detail.nameCode}}</div>
        </div>
      </div>
      <div class="article-text">
        <div class="article-videoBox">
          <!-- <video :src="detail.url" controls v-if="isVideo()"></video> -->
          <LivePlayer v-if="detail.url && (detail.url.indexOf('.flv') >= 0 )" :videoUrl="liveUrl" fluent autoplay live stretch></LivePlayer>
          <template v-else-if="detail.url && (detail.url.indexOf('.m3u8') >= 0|| detail.url.indexOf('rtmp://') >= 0) && isStart">
            <video-player
              class="video-player vjs-custom-skin"
              ref="videoPlayer"
              :playsinline="true"
              :options="onlineOptions"
            >
            </video-player>
          </template>
          <Player ref="player" v-else-if="!!detail.liveId" :liveId="detail.liveId"/>
          <template v-else-if="!detail.liveId">
            <!-- 多个视频 -->
            <template v-if="isVideo() && videoArr.length > 1">
              <div class="videos-box">
                <video
                  id="detailVideo"
                  controls="controls" loop="loop" 
                  preload="auto" 
                  :src="videoUrl"
                  :poster="detail.thumb"
                >
                </video>
              </div>
              <div class="video-btns">
                <div v-for="(video, index) in videoArr" 
                :key="index" class="video-btn" 
                :class="videoIndex === index ? 'active':''"
                :title="video.name"
                @click="changeVideo(index)"
                >
                  <div class="video-name">{{index + 1}}/{{video.name}}</div>
                </div>
              </div>
            </template>
            <video v-else-if="isVideo()"
              controls="controls" loop="loop" 
              preload="auto" 
              :src="detail.url"
              :poster="detail.thumb"
            >
            </video>
            <div v-else-if="detail.articleUrl" class="img-txt-detail">
              <iframe id="detailIframe" :src="detail.articleUrl" height="100%" allowfullscreen="true"></iframe>
            </div>
            <div v-else-if="imageUrls.length" class="swiper-images swiper-container">
              <div class="swiper-wrapper" style="position: relative;">
                <div class="swiper-slide" v-for="(item,index) in imageUrls" :key="index">
                  <img :src="item" alt="">
                </div>
              </div>
              <div class="swiper-pagination images-pagination"></div>
            </div>
            <div v-else class="thumbImgBpx">
              <img v-if="!!detail.thumb" :src="detail.thumb">
              <img v-else src="~@/assets/img/detailImg/defaultBg.png">
            </div>
          </template>
          
          <!-- <img :src="detail.thumb" class="article-video-Page" v-if="showVideoPic && !showDetault && showPic" @click="changePic" @error="showDetault = true"/>
          <img src="~@/assets/img/detailImg/defaultBg.png" class="article-video-Page" v-else-if="(!showVideoPic || showDetault)&& showPic" @click="changePic" /> -->
        </div>

        <div v-if="tabList.length" class="column-box">
          <div class="choose-column">
            <!-- <a v-for="item in tabList" :key="item.id" @click="checkTab(item)" :class="{'active': activeId === item.id}">{{item.name}}</a> -->
            <div v-for="(item,index) in tabList" :key="item.id" class="roadshow-type-item" :class="{'item-active': activeId === item.id}" @click="checkTab(index, item)">
              <span class="roadshow-type-item-type ">
                {{item.name}}
              </span>
              <span class="roadshow-type-board" :class="{noShowBack: templateIndex === index || templateIndex === index+1}">
              </span>
            </div>
          </div>
          <div class="column-content">
            <iframe v-if="tabData.itemType === '5' && tabData.con1" :src="tabData.con1" frameborder="0"></iframe>
            <div class="each-content active" data-text="企业介绍" v-else-if="tabData.itemType != '4'">
              <!-- 1 文本  2 图片  3 富文本 4 评论列表 -->
              <p class="column-text" v-html='changeContent(tabData.content)' v-if="tabData.content"></p>
              <div class="article-file" v-if="tabData.fileList">
                <p class="each-file" v-for="item in tabData.fileList" :key="item.id" @click="downloadFile(item)">

                  <img class="file-icon" :src="setfileType(item)" />
                  <a data-src="aa" id="file_name" class="file-name"> {{item.rawName}}</a>
                  <a download="aa" class="download-file"></a>
                </p>
              </div>
              <NoData v-if="!tabData.content && !tabData.fileList" bottom="92px" />
            </div>

            <div class="each-content" data-text="最新互动" v-else>
              <div>
                <div class="user-comment comment-list" ref="commentList" v-if="userCommentList.length">
                  <div class="user-comment-item" v-for="(item) in userCommentList" :key="item.id">
                    <div class="user-msg">
                      <img class="avatar" :src="item.portrait" alt="头像" v-if="item.portrait && !item.error" @error="item.error=true">
                      <img class="avatar" src="~@/assets/img/person/person.svg" alt="头像" v-else-if="!!item.isUser">
                      <img class="avatar" src="~@/assets/img/person/company.svg" alt="头像" v-else>
                      <!-- <img class="avatar" :src="" alt="头像"> -->
                      <p class="name-time">
                        <span class="name">{{item.userName}} </span>&nbsp;·&nbsp;
                        <span class="time">{{item.createTime}}</span>
                      </p>
                    </div>
                    <div class="comment-content">
                      <span class="horn"></span>
                      <p v-html="item.commentContent"></p>
                    </div>
                    <!-- <p class="user-comment-tip">
                      您的评论经筛选后向所有人展示
                      <span class="user-comment-del" @click="deleteUserComment(item.id, index)"></span>
                    </p> -->
                  </div>
                  <!-- <p class="loading-more" v-if="!userCommentForm.finished && !userCommentForm.loadMore && userCommentList.length" @click="getUserMore" style="margin-top:10px">加载更多</p> -->
                  <p class="load-finish" v-if="userCommentForm.finished && userCommentList.length >3" style="margin-top:10px">加载完毕</p>
                  <p class="loading-data" v-if="userCommentForm.loadMore && userCommentList.length" style="margin-top:10px"><img src="@/assets/img/article_list/loading.gif" />加载中...</p>
                </div>
                <div class="comment-box">
                  <textarea class="comment-text" v-model="userComment" :readonly="!isLogin" maxlength="200" :placeholder="isLogin ? (isBusiness ? '您当前登录的是企业账号！' : '你怎么看...') : '登录后才能参与互动哦...'"></textarea>
                  <div class="comment-tip">
                    <p class="login-tip">
                      <template v-if="isLogin">
                        <template  v-if="!!isBusiness">
                          <img class="user-avatar" :src="$store.state.localInfoMsg.logo" alt="头像" v-if="$store.state.localInfoMsg.logo && !portraitError" @error="portraitError = true">
                          <img class="user-avatar" src="~@/assets/img/person/company.svg" alt="头像" v-else>
                          <span class="user-name">{{$store.state.localInfoMsg.username}}</span>
                        </template>
                        <template v-else>
                          <img class="user-avatar" :src="$store.state.localInfoMsg.portrait" alt="头像" v-if="$store.state.localInfoMsg.portrait && !portraitError" @error="portraitError = true">
                          <img class="user-avatar" src="~@/assets/img/person/person.svg" alt="头像" v-else>
                          <span class="user-name">{{$store.state.localInfoMsg.custName}}</span>
                        </template>
                      </template>
                      <template v-else>
                        <router-link to="/login">
                          <a class="login-link">登录</a>
                        </router-link>
                        <span>后参与互动</span>
                      </template>
                    </p>
                    <p class="num-tip">
                      <span id="comment-num">{{userComment.length}}</span>
                      <span>/ 200</span>
                      <a class="submit-comment" @click="postInsertComment">发送</a>
                    </p>
                  </div>
                </div>
                <!-- <NoData v-if="!userCommentList.length && isLogin"/> -->
              </div>
              <!-- <div class="select-comments comment-list">
                <h3 class="select-title">精选评论</h3>
                <div class="select-list" v-if="selectedCommentList.length">
                  <div class="each-select" v-for="item in selectedCommentList" :key="item.id">
                    <div class="user-msg">
                      <img class="avatar" :src="`${item.portrait}`" alt="头像" v-if="item.portrait && !item.error" @error="item.error=true">
                      <img class="avatar" src="~@/assets/img/person/person.svg" alt="头像" v-else>
                      <span class="name">{{item.userName}}</span>&nbsp;
                      <span class="time">·&nbsp;{{item.createTime}}</span>
                    </div>
                    <p class="comment-content">{{item.commentContent}}</p>
                  </div>
                </div>
                <NoData v-else bottom="92px" />
                <p class="loading-more" v-if="!selectedCommentForm.finished && !selectedCommentForm.loadMore && selectedCommentList.length" @click="getSelectedMore">加载更多</p>
                <p class="load-finish" v-if="selectedCommentForm.finished && selectedCommentList.length">加载完毕</p>
                <p class="loading-data" v-if="selectedCommentForm.loadMore && selectedCommentList.length"><img src="@/assets/img/article_list/loading.gif" />加载中...</p>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <DelModel :delFlag="delFlag" @delCancel="delCancel" @delConfirm="delConfirm"/> -->
    <!-- <Tips v-if="abnormalTip" :isLoginLink="isLoginLink" :abnormalTxt="abnormalTxt" @closeModel="closeModel" /> -->
    <!-- 提交成功弹框 -->
    <div class="model question_model" v-show="submitSuccess">
      <div class="feedback_success">
          <div class="feedback_title">发送成功</div>
          <div class="feedback_success_icon"></div>
          <span class="feedback_success_button" @click="submitSuccess=false"> 关闭 </span> 
        </div>
    </div>
  </div>
</template>
<script>
// import '@/assets/css/detail'
import LivePlayer from '@liveqing/liveplayer'
import NoData from "@/components/NoData";
import "@/assets/css/detail.less";
import { setCatalogTree } from "@/utils/store-data";
import QRCode from 'qrcodejs2';
// import DelModel from '@/components/delModel';
import BuryingPont from "@/mixins/buryingPoint.js";
import Player from './components/player'
import 'swiper/dist/idangerous.swiper.css'
import Swiper from 'swiper/dist/idangerous.swiper.js'
import 'videojs-flash'
import {
  getDetail,
  getDetailTabList,
  postInsertComment,
  getSelectedCommentList,
  // getUserCommentList,
  collectRold,
  // deleteUserComment,
  // liveList,
  // getLiveInfo
  queryLiveItemByName
} from "@/api/rold";
// import Tips from "@/components/Tips";
export default {
  mixins: [BuryingPont],
  data() {
    return {
      nowRoute: this.$route.fullPath,
      id: "",
      activeId: "",
      detail: {},
      showVideoPic: true, //是否展示。视频默认图片
      tabData: {
        id: "",
        content: "",
        itemType: "1", //1-文本  2-图片  3-富文本  4-评论列表
        fileList: []
      },
      delFlag: false,
      delId: null,
      delIndex: null,
      tabList: [], //Tab列表
      totalCount: 0,
      userCommentList: [], //自我评价
      // selectedCommentList: [], //精选评价

      userComment: "", //用户输入的评论
      userCommentForm: {
        // 个人评价
        pageNo: 1,
        pageSize: 4,
        finished: false,
        loadMore: false,
        loading: false
      },
      // selectedCommentForm: {
      //   // 精品评价
      //   pageNo: 1,
      //   pageSize: 3,
      //   finished: false,
      //   loadMore: false,
      //   loading: false
      // },
      // abnormalTip: false,
      // isLoginLink: false,
      // abnormalTxt: "",
      templateIndex: 0,

      showDetault: false,
      showPic: true,
      portraitError: false,
      postInsertFlag: false,
      submitSuccess: false,
      videoArr: [],
      videoUrl: '',
      videoIndex: 0,
      imageUrls: [],
      onlineOptions: {},
      // liveUrl: 'http://xcyplay.xinhuaxmt.com/zbcb/1655687890145674.m3u8?auth_key=1656983957-0-0-95e5ac7de4435a92504ceb428ced3441',
      // liveUrl: 'rtmp://xcyplay.xinhuaxmt.com/zbcb/1655687890145674?auth_key=1656983890-0-0-9f14efbae435b7a82d6ff4e2fc5f1e8c',
      // liveUrl: 'https://sf1-hscdn-tos.pstatp.com/obj/media-fe/xgplayer_doc_video/flv/xgplayer-demo-360p.flv',
      liveUrl: '',
      isStart: false,
      timeOut: null,
      liveInter: null
    };
  },
  components: { NoData, Player, LivePlayer},//
  computed: {
    isLogin() {
      return this.$store.state.token;
    },
    isBusiness() {
      return (
        this.$store.state.token && this.$store.state.token.indexOf("BC_") != -1
      );
    },
    shareHide() {
      return this.detail.isHide === 1
    },
    isHide() {
      return this.$route.query.ishide === '1'
    }
  },
  beforeRouteLeave(to,from,next){
    window.clearTimeout(window.timerSet)
    next();
  },
  created() {
    setCatalogTree(this.$store);
    if (this.$route.query.id) this.id = this.$route.query.id;

    this.getDetail();
    this.getDetailTabList();
    this.getUserCommentList();
  },
  mounted(){
    // liveList({c:'all'}).then(res=>{
    //   console.log(res)
    // }).catch(err=>{
    //   console.log(err)
    // })
    // getLiveInfo({liveId:10001,upNum:'',isH5:'',reviewerId:''}).then(res=>{
    //   console.log(res)
    // }).catch(err=>{
    //   console.log(err)
    // })
    if (this.isHide) {
      document.querySelector('#nav').style.display = 'none'
      document.querySelector('#footer').style.display = 'none'
      document.querySelector('.detail-box').style.paddingTop = '0'
      document.querySelector('title').innerText = this.$route.query.title || '新华财经路演中心'
    }
  },
  methods: {
    //切换tab
    checkTab(index, data) {
      for (let key in data) {
        this.$set(this.tabData, key, data[key]);
      }
      if (this.tabData.itemType === '5' && this.tabData.content) {
        this.tabData.con1 = this.tabData.content.split('$$##$$')[0]
      }
      this.activeId = data.id;
      this.templateIndex = index;
      if(data.itemType == 4){
        setTimeout(() => {
          this.checkScroll();
        }, 200);
      }
    },
    changeContent(content){
      return content.replace(/&lt;/g,'<').replace(/&gt;/g,'>')
    },
    //收藏
    collectRold() {
      if (this.isLogin) {
        if (this.isBusiness) {
          // this.abnormalTip = true;
          // this.abnormalTxt = "企业用户不可收藏，请";
          // this.isLoginLink = true;
          this.$message({tipTxt:'企业用户不可收藏，请',isLink:true,loginTxt:'登录个人账户'});
        } else {
          collectRold({
            id: this.id,
            type: "10"
          }).then(() => {
            this.detail.collectStatus = !this.detail.collectStatus;
            // 收藏埋点
            if(this.detail.collectStatus){
              this.sensors.track( 'LY_collection_click',{
                AppName: this.$store.state.AppName,  
                click_content: '收藏',
                collect_title: this.detail.title,
                is_login: false, 
                platform: this.$store.state.platform
              })
            }
            // 收藏埋点
            if(!this.detail.collectStatus){
              this.sensors.track( 'LY_cancel_collection_click',{
                AppName: this.$store.state.AppName,  
                click_content: '取消收藏',
                collect_title: this.detail.title,
                is_login: false, 
                platform: this.$store.state.platform
              })
            }
          });
        }
      } else {
        this.$router.push("/login");
      }
    },
    // 关闭弹窗
    // closeModel(data) {
    //   this.abnormalTxt = data.tipText;
    //   this.isLoginLink = data.linkFlag;
    //   this.abnormalTip = data.tipFlag;
    // },
    //路演详情
    getDetail() {
      getDetail({
        id: this.id
      }).then(res => {
        for (let key in res.data) {
          this.$set(this.detail, key, res.data[key]);
        }
        const names = (this.detail.officeName || '').split(',')
        const codes = (this.detail.officeCode || '').split(',')
        const nameCode = names.map((name, i) => {
          return name + (codes[i] ? `（${codes[i]}）` : '')
        }).join('，')
        this.$set(this.detail, 'nameCode', nameCode);
        const host = window.location.host.indexOf('pictest-test.cnfic.com.cn') >= 0 ? `${window.location.protocol}//${window.location.host}/hs` : `${window.location.protocol}//${window.location.host}`
        if (this.detail.mp4) {
          this.detail.url = host + this.detail.mp4
        } else if (this.detail.m3u8) {
          this.detail.url = host + this.detail.m3u8
        }
        if (this.detail.url.endsWith('mp4,')) {
          this.detail.url = this.detail.url.substring(0, this.detail.url.length - 1)
        }
        // if (this.detail.url.indexOf('rtmp://') >= 0) {
        //   if (this.detail.mp4) {
        //     const host = window.location.host.indexOf('pictest-test.cnfic.com.cn') >= 0 ? `${window.location.protocol}//${window.location.host}/hs` : `${window.location.protocol}//${window.location.host}`
        //     this.detail.url = host + this.detail.mp4
        //   } else {
        //     const host = window.location.host.indexOf('pictest-test.cnfic.com.cn') >= 0 ? `${window.location.protocol}//${window.location.host}/hs` : `${window.location.protocol}//${window.location.host}`
        //     this.detail.url = host + this.detail.m3u8
        //   }
        // }
        const arr = this.detail.url?.split(',') || []
        const nameArr = this.detail.videoTitle?.split(',') || []
        this.videoArr = []
        arr.forEach((el, i) => {
          if (!el) return
          this.videoArr.push({
            name: nameArr[i] || '录播' + (i + 1),
            url: el
          })
        })
        if (this.videoArr.length) {
          this.videoUrl = this.videoArr[0].url
        }
        if (this.detail.imageUrls) {
          let imageUrls = []
          try {
            imageUrls = JSON.parse(this.detail.imageUrls)
          } catch (err) {
            imageUrls = []
          }
          this.imageUrls =  imageUrls.map((item) => {
            return item.url
          }) || []
          if (this.imageUrls.length > 0)  {
            this.$nextTick(() => {
              new Swiper('.swiper-images',{
                speed: 300,
                loop: this.imageUrls.length>1?true:false,
                pagination: '.images-pagination',
                paginationClickable :true
              })
            })
          }
        }
        if (this.detail.url && (this.detail.url.indexOf('.m3u8') >= 0 || this.detail.url.indexOf('rtmp://') === 0 || this.detail.url.indexOf('.flv') >= 0 || this.detail.url.indexOf('/flv') >= 0)) {
          const time = (this.detail.publishedTime && new Date(this.detail.publishedTime).getTime() - 600000 - new Date().getTime())
          if (time < 0) {
            this.isStart = true
          } else if (time > 0) {
            this.timeOut = setTimeout(() => {
              this.isStart = true
              clearTimeout(this.timeOut)
              this.timeOut = null
            }, time);
          }
          let playUrl = ''
          if (this.detail.url.indexOf('http://xcyplay.xinhuaxmt.com') === 0) {
            let host = window.location.host.indexOf('pictest-test.cnfic.com.cn') >= 0 ? `${window.location.protocol}//${window.location.host}/hs` : `${window.location.protocol}//${window.location.host}`
            playUrl = host + this.detail.url.replace('http://xcyplay.xinhuaxmt.com', '/xcyp')
          } else if (this.detail.url.indexOf('http://play.szs.nagasoft.cn') === 0) {
            let host = window.location.host.indexOf('pictest-test.cnfic.com.cn') >= 0 ? `${window.location.protocol}//${window.location.host}/hs` : `${window.location.protocol}//${window.location.host}`
            playUrl = host + this.detail.url.replace('http://play.szs.nagasoft.cn', '/psnc')
          } else {
            playUrl = this.detail.url
          }
          this.liveUrl = playUrl
          this.onlineOptions = {
            playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
            autoplay: false, //如果true,浏览器准备好时开始回放。
            muted: false, // 默认情况下将会消除任何音频。
            loop: false, // 导致视频一结束就重新开始。
            preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
            language: "zh-CN",
            aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
            fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
            sources: [
              {
                withCredentials: false,
                type: "rtmp/mp4",
                src: playUrl,
              },
              {
                withCredentials: false,
                type: "application/x-mpegURL", //播放类型，如果为rtmp配置为rtmp/mp4
                src: playUrl,
              },
            ],
            poster: this.detail.thumb, //你的封面地址
            width: document.documentElement.clientWidth,
            notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
            controlBar: {
              // timeDivider: true,
              // durationDisplay: true,
              // remainingTimeDisplay: false,
              // fullscreenToggle: true, //全屏按钮
              timeDivider: true,
              durationDisplay: true,
              remainingTimeDisplay: true,
              currentTimeDisplay: true, // 当前时间
              volumeControl: false, // 声音控制键
              playToggle: true, // 暂停和播放键
              progressControl: true, // 进度条
              fullscreenToggle: true, // 全屏按钮
            },
          };
        }
        this.creatQrCode();
        this.$nextTick(() => {
          if (document.getElementById('detailIframe')) {
            const width = document.getElementsByClassName('detail-box')[0].offsetWidth
            document.getElementById('detailIframe').style.width = width + 'px'
          }
        })
        window.addEventListener('resize', ()=> {
          if (document.getElementById('detailIframe')) {
            const width = document.getElementsByClassName('detail-box')[0].offsetWidth
            document.getElementById('detailIframe').style.width = width + 'px'
          }
        })
        // 浏览详情页埋点
        this.sensors.track( 'LY_view_video_page' ,{
          AppName: this.$store.state.AppName, 
          page_id: '',
          content_sources: this.detail.officeName,
          release_time: this.detail.publishedTime,
          title: this.detail.title,
          content_id: this.detail.id,
          is_login: false, 
          platform: this.$store.state.platform,
        })
      });
    },
    //路演详情页面tab信息
    getDetailTabList() {
      getDetailTabList({
        pageNo: 1,
        pageSize: 999,
        liveId: this.id
      }).then(res => {
        this.tabList = res.data;
        if (res.data.length) {
          this.activeId = res.data[0].id;
          Object.assign(this.tabData, this.tabList[0]);
          if (this.tabData.itemType === '5' && this.tabData.content) {
            this.tabData.con1 = this.tabData.content.split('$$##$$')[0]
          }
        }
        this.queryLiveItemByName()
      });
    },
    queryLiveItemByName() {
      // console.log(this.tabList)
      const tab = this.tabList.filter((item)=>{
        return item.name === '历史交流'
      })[0]
      const index = this.tabList.findIndex((item)=>{
        return item.name === '历史交流'
      })
      console.log(tab, index)
      this.liveInter = setInterval(() => {
        queryLiveItemByName({
          liveId: tab.liveId,
          templateId: tab.templateId,
          name: tab.name,
          pageNo: 1,
          pageSize: 999
        }).then((res)=>{
          Object.assign(tab, res?.data || {});
          this.$set(this.tabList, index, tab)
          if (this.tabData.name === '历史交流') {
            this.$set(this.tabData, 'content', res?.data?.content || '');
          }
        })
      }, 60000);
    },
    //下载附件
    downloadFile(data) {
      window.open( data.host + data.url);
    },
    //插入评价
    postInsertComment() {
      if (this.isLogin) {
        if(!this.postInsertFlag){
          this.postInsertFlag = true;
          if(this.userComment.length <=2) {
            // this.abnormalTip = true
            // this.abnormalTxt = '评论字数必须大于两个字'
            this.$message({tipTxt:'评论字数必须大于两个字',isLink:false});
            this.postInsertFlag = false;
            return
          } 
          postInsertComment({
            liveId: this.id,
            commentContent: this.userComment
          }).then((res) => {
            if(res.code == 0){
              Object.assign(this.userCommentForm, {
                loading: false,
                finished: false,
                loadMore: false,
                pageNo: 1
              });
              this.userComment = "";
              this.submitSuccess = true;
              // window.scrollTo(0, 0);
              this.getUserCommentList();
              // this.postInsertFlag = false;
              // 提交评论埋点
              this.sensors.track( 'LY_click_comment_submit',{
                AppName: this.$store.state.AppName,  
                comment_content: this.detail.title,
                is_login: true, 
                platform: this.$store.state.platform
              })
            }else{
              this.postInsertFlag = false;
              this.$message({tipTxt:res.msg,isLink:false});
            }
          }).catch(()=>{
            this.postInsertFlag = false;
          });
        }
      } else {
        this.$router.push("/login");
      }
    },
    // //删除自我评价
    // deleteUserComment(id, index) {
    //   this.delFlag = true;
    //   this.delId = id;
    //   this.delIndex = index;
    // },
    // // 取消删除
    // delCancel(data){
    //   this.delFlag = data;
    // },
    // 确认删除
    // delConfirm(data){
    //   if(data){
    //     deleteUserComment({
    //       commentId: this.delId
    //     }).then(() => {
    //       this.userCommentList.splice(this.delIndex, 1);
    //       this.getUserCommentList(true);
    //       this.delFlag = false;
    //       this.delId = null;
    //       this.delIndex = null;
    //     });
    //   }
    // },

    // getUserMore() {
    //   Object.assign(this.userCommentForm, {
    //     loading: true,
    //     finished: false,
    //     loadMore: true,
    //     pageNo: this.userCommentForm.pageNo + 1
    //   });
    //   // this.formCompany.pageNo++;
    //   this.getUserCommentList();
    // },
    //自我评价列表
    getUserCommentList(replace = false) {
      const { pageNo, pageSize } = this.userCommentForm;
      getSelectedCommentList({
        liveId: this.id,
        pageNo,
        pageSize
      })
        .then(res => {
          this.totalCount = res.count;
          if (res.data.length) {
            res.data.forEach(item=>{item.error = false;})
            if (replace) {
              var resItem = res.data[res.data.length - 1];
              var noSame = true;
              this.userCommentList.forEach(item => {
                if (item.id == resItem.id) noSame = false;
              });
              if (noSame) {
                this.userCommentList.push(resItem);
              }
            } else {
              if(pageNo == 1) this.userCommentList = []
              res.data.map(item=>{
                item.commentContent = '<span>'+item.commentContent.replace(/\n/g,'<br>')+'</span>';
                this.userCommentList.push(item)
              })
            }
            // if(pageNo == 1) this.userCommentList = []

            if (this.userCommentList.length >= res.count)
              this.userCommentForm.finished = true;
            // if (!this.userCommentForm.finished && pageNo % 2 == 0)
            //   this.getUserMore();
          }
        })
        .finally(() => {
          this.userCommentForm.loading = false;
          this.userCommentForm.loadMore = false;
          this.postInsertFlag = false;
        });
    },
    // 评论滚动加载
    lowEnough() {
      var commentList = this.$refs.commentList;
      var pageHeight = commentList.scrollHeight;
      var viewportHeight = commentList.clientHeight;
      var scrollHeight = commentList.scrollTop;
      return pageHeight - viewportHeight - scrollHeight <= 10 && !this.userCommentForm.loading && this.userCommentList.length<this.totalCount;
    },
    doSomething() {
      Object.assign(this.userCommentForm, {
        loading: true,
        finished: false,
        loadMore: true,
        pageNo: this.userCommentForm.pageNo + 1
      });
      this.getUserCommentList();
      this.pollScroll(); //继续循环
    },
    checkScroll() {
      if (!this.lowEnough()){
        return this.pollScroll();
      }
      setTimeout(this.doSomething, 100);
    },
    pollScroll() {
      setTimeout(this.checkScroll, 200);
    },

    //加载更多 精选评论
    // getSelectedMore() {
    //   Object.assign(this.selectedCommentForm, {
    //     loading: true,
    //     finished: false,
    //     loadMore: true,
    //     pageNo: this.selectedCommentForm.pageNo + 1
    //   });
    //   this.getSelectedCommentList();
    // },
    //获取精选评论
    // getSelectedCommentList() {
    //   const { pageNo, pageSize } = this.selectedCommentForm;
    //   getSelectedCommentList({
    //     pageNo,
    //     pageSize,
    //     liveId: this.id
    //   })
    //     .then(res => {
    //       if (res.data.length) {
    //         res.data.forEach(item=>{
    //           item.error = false;
    //         })
    //         this.selectedCommentList = this.selectedCommentList.concat(
    //           res.data
    //         );
    //         if (this.selectedCommentList.length >= res.count)
    //           this.selectedCommentForm.finished = true;
    //         // if (pageNo % 2 == 0 && !this.selectedCommentForm.finished)
    //         //   this.getSelectedMore();
    //       }
    //     })
    //     .finally(() => {
    //       this.selectedCommentForm.loading = false;
    //       this.selectedCommentForm.loadMore = false;
    //     });
    // },
    //隐藏视频图片
    changePic() {
      this.showVideoPic = false;
      this.showDetault = false
      this.showPic = false
    },
    //判断是否为视频类型
    isVideo() {
      if(this.detail.url) {
        const type = this.detail.url.split('.').pop().toLowerCase()
        switch(type){
          case 'mp3':
          case 'mp4':
          case 'webm':
          case 'avi':
          case 'mov':
          case 'qt':
          case 'asf':
          case 'rm':
          case 'ra':
          case 'ram':
          case 'mpeg':
          case 'mpg':
          case 'dat':
          case 'ogg':
          case 'mod':
          case 'mkv':return true;
          default: return false;
        }
      }
    },
    // 切换视频
    changeVideo(index) {
      this.videoIndex = index
      this.videoUrl = this.videoArr[index].url
      this.$nextTick(() => {
        document.getElementById('detailVideo').play()
      })
    },
    //设置下载文件的图标
    setfileType(data) {
      if (data) {
        var list = data.rawName.split(".");
        var img = "";
        switch (list[list.length - 1]) {
          case "mp4":
            img = require("@/assets/img/detailImg/video.svg");
            break;
          case "xls":
            img = require("@/assets/img/detailImg/excel.svg");
            break;
          case "xlsx":
            img = require("@/assets/img/detailImg/excel.svg");
            break;
          case "png":
            img = require("@/assets/img/detailImg/img.svg");
            break;
          case "jpg":
            img = require("@/assets/img/detailImg/img.svg");
            break;
          case "jpeg":
            img = require("@/assets/img/detailImg/img.svg");
            break;
          case "gif":
            img = require("@/assets/img/detailImg/img.svg");
            break;
          case "mp3":
            img = require("@/assets/img/detailImg/mp3.svg");
            break;
          case "doc":
            img = require("@/assets/img/detailImg/word.svg");
            break;
          case "docx":
            img = require("@/assets/img/detailImg/word.svg");
            break;
          case "dot":
            img = require("@/assets/img/detailImg/word.svg");
            break;
          case "dotx":
            img = require("@/assets/img/detailImg/word.svg");
            break;
          case "wpt":
            img = require("@/assets/img/detailImg/word.svg");
            break;
          case "zip":
            img = require("@/assets/img/detailImg/zip.svg");
            break;
          case "7z":
            img = require("@/assets/img/detailImg/zip.svg");
            break;
          case "pdf":
            img = require("@/assets/img/detailImg/pdf.svg");
            break;
          default:
            img = "";
            break;
        }
        return img;
      }
      return;
    },

        // 分享到新浪微博
    shareSina(){
      this.sinaWeiBo(this.detail.title);
    },
    sinaWeiBo(title, url, pic) {
      var param = {
        url: (url || window.location.href) + (this.shareHide ? '&ishide=1&title=' + this.detail.title : ''),
        type: '3',
        count: '1', /** 是否显示分享数，1显示(可选)*/
        appkey: '', /** 您申请的应用appkey,显示分享来源(可选)*/
        title: title, /** 分享的文字内容(可选，默认为所在页面的title)*/
        pic: pic || '', /** 分享图片的路径(可选)*/ 
        ralateUid:'', /** 关联用户的UID，分享微博会@该用户(可选)*/
        rnd: new Date().valueOf()
      }
      var temp = [];
      for( var p in param ) {
          temp.push(p + '=' +encodeURIComponent( param[p] || '' ) )
      }
      var targetUrl = 'http://service.weibo.com/share/share.php?' + temp.join('&');
      window.open(targetUrl, 'sinaweibo', 'height=430, width=400');
    },
    // 微信分享二维码
    creatQrCode() {
      new QRCode(this.$refs.wechartQr, {
          text: window.location.href.replace("/ly/#/",'/mly/#/') + (this.shareHide ? '&ishide=1&title=' + this.detail.title : ''), // 需要转换为二维码的内容
          width: 128,
          height: 128,
          colorDark: '#000000',
          colorLight: '#ffffff',
          correctLevel: QRCode.CorrectLevel.H
      })
    },
    //分享到QQ
    shareQQ() {
      var shareUrl = window.location.href.replace("/ly/#/",'/mly/#/');
      this.qqShare(this.detail.title,shareUrl)
    },
    qqShare: function (title, url, pic) {
      var param = {
        url: (url || window.location.href) + (this.shareHide ? '&ishide=1&title=' + this.detail.title : ''),
        desc: '', /*分享描述*/
        title : title || '', /*分享标题(可选)*/
        summary : '',/*分享描述(可选)*/
        pics : pic || '',/*分享图片(可选)*/
        flash : '', /*视频地址(可选)*/
        site: '' /*分享来源 (可选) */
      };
      var s = [];
      for (var i in param) {
        if(i == 'url'){
          s.push(i + '=' + encodeURIComponent(param[i] || ''));
        }else{
          s.push(i + '=' + (param[i] || ''));
        }
      }
      var targetUrl = 'http://connect.qq.com/widget/shareqq/index.html?' + s.join('&') ;
      window.open(targetUrl, 'qq', 'height=520, width=720');
    },
    
    reloadPage(){
      this.$router.go(0);
    },
  },
  beforeDestroy() {
    if (this.timeOut) {
      clearTimeout(this.timeOut)
      this.timeOut = null
    }
    clearInterval(this.liveInter)
  }
};
</script>

